import React from "react";
import { ingredients } from "../data";
import { FoodSource } from "./FoodSource";

export const Ingredients = () => {
  return (
    <React.Fragment>
      <span className="text-center">
        <h1>INGREDIENTS</h1>
      </span>
      <dl className="row">
        <dd className="col-sm-12 mb-4">
          We only use ingredients that your great grandmother would recognize,
          so NO vegetable/seed oils, msg or GMO's. We try to source our
          ingredients local and organic if available. Our potatoes are fried in
          pure animal fat (duck fat and or lard). Or if your vegetarian/vegan
          (butter or olive oil).
        </dd>
      </dl>

      <span className="text-center"></span>
      {ingredients.map((item, idx) => (
        <FoodSource key={idx} data={item} />
      ))}
    </React.Fragment>
  );
};
