import React from "react";

export const FoodSource = (props) => {
  return (
    <dl className="row">
      <dt className="col-4 ml-auto">
        <span className="float-right">{props.data.name}</span>
      </dt>
      <dd className="col-8">
        <a href={props.data.link}>{props.data.producer}</a>
      </dd>
    </dl>
  );
};
