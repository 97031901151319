import React from "react";

export const PressDescription = (props) => {
  return (
    <dl className="row">
      <dt className="col-sm-2">{props.data.publication}</dt>
      <dd className="col-sm-10 ">
        {props.data.title}
        <a className="ms-2" variant="primary" href={props.data.href}>
          Read More...
        </a>
      </dd>
    </dl>
  );
};
