export const ingredients = [
  {
    name: "greens",
    producer: "altius farms - Denver, CO",
    link: "https://altiusfarms.com/",
  },
  {
    name: "bacon",
    producer: "tender belly - Denver, CO",
    link: "https://www.tenderbelly.com/",
  },
  {
    name: "sausage",
    producer: "polidori - Denver, CO",
    link: "https://polidorisausage.com/",
  },
  {
    name: "chicken",
    producer: "boulder natural meats - Boulder, CO",
    link: "https://www.facebook.com/BoulderNaturalMeats",
  },
  {
    name: "pork",
    producer: "niman ranch",
    link: "https://www.nimanranch.com/",
  },
  {
    name: "pastries",
    producer: "sugar vision - Denver, CO",
    link: "https://www.facebook.com/SugarVision",
  },
  {
    name: "coffee",
    producer: "middle state - Denver, CO",
    link: "https://www.middlestatecoffee.com/",
  },
  {
    name: "organic tea",
    producer: "teaspot - Boulder, CO",
    link: "https://www.theteaspot.com/",
  },
  {
    name: "wine",
    producer: "infinite monkey theorem - Denver, CO",
    link: "https://theinfinitemonkeytheorem.com/",
  },
  {
    name: "fish sauce",
    producer: "red boat fish sauce",
    link: "https://redboatfishsauce.com/",
  },
  { name: "organic tamari", producer: "san j", link: "https://san-j.com/" },
];

export const cateringLunch = [
  {
    name: "Carne Asada Taco bar | $130 each feeds 10",
    desc:
      "20 housemade organic flour tortillas (gf corn tortillas add $10) , queso fresco, pico de gallo, guacamole, carne asada",
  },
  {
    name: "Carne Asada bar | $130 each feeds 10",
    desc:
      "Carne Asada, rice, beans, guacamole, pico de gallo, asadero cheese, fried jalapenos and 10 organic home made tortillas",
  },
  {
    name: "Duck Fried Rice (gf) | $130 feeds 10",
    desc:
      "Fried rice with duck, jasmine rice, garlic, bok choy, scrambled eggs and chili oil",
  },
  {
    name: "Barbacoa Taco Bar | $130 feeds 10",
    desc:
      "20 housemade organic flour tortillas (gf corn tortillas add $10) , queso fresco, pico de gallo, avocado , barbacoa",
  },
];

export const cateringBreakfast = [
  {
    name: "BREAKFAST BURRITOS | $12.00 each",
    desc:
      "housemade organic flour tortilla, (choice of bacon, sausage, chorizo, vegetarian,vegan), green chili, eggs, potatoes, asadero cheese",
  },
  {
    name: "BACON FRIED RICE (gf) (s) | $120 serves up to 10",
    desc:
      "rice, scrambled eggs, bacon, garlic, green onions, tamari soy sauce, chili oil",
  },
  {
    name: "BREAKFAST TACOS BAR | $120 (gluten free add $10) serves up to 10",
    desc:
      "Scrambled eggs, crispy shoestring potatoes, choice of (bacon, sausage, chorizo, vegetarian) , asadero cheese, served with 20 housemade tortillas, red and green salsa",
  },
  {
    name: "CHINESE SAUSAGE FRIED RICE (gf) (s) | $120 serves up to 10",
    desc:
      "rice, scrambled eggs, lap chong sausage, garlic, green onions, tamari soy sauce, chili oil",
  },
  {
    name: "COFFEE BOX | $30 serves up to 10",
    desc: "CommonWealth Single Origin",
  },
];

export const press = [
  {
    publication: "Travel + Leisure",
    href:
      "https://www.travelandleisure.com/food-drink/restaurants/best-breakfast-restaurants-in-the-us?slide=58c64e44-2893-4809-85a6-0457e3172654#58c64e44-2893-4809-85a6-0457e3172654",
    title: "Travel and Leisure's The Very Best Breakfast Spots in the U.S",
  },
  {
    publication: "Thrillist",
    href: "https://www.thrillist.com/eat/denver/best-restaurants-denver",
    title: "Thrillist Best Restaurants in Denver 2017",
  },
  {
    publication: "5280",
    href: "http://www.5280.com/tott/digital/2015/06/top-town-2015-dining",
    title: "5280's top of the town 2015",
  },
  {
    publication: "The Westword",
    href:
      "http://www.westword.com/restaurants/review-onefold-cooks-up-a-delicious-way-to-start-your-day-7192574",
    title: "Denver Westword's Review",
  },
  {
    publication: "5280",
    href: "http://www.5280.com/magazine/2016/02/where-eat-now?page=full",
    title: "5280's bucketlist eats",
  },
  {
    publication: "The Westword",
    href:
      "http://www.westword.com/restaurants/the-fifteen-best-new-restaurants-in-denver-in-2015-7441703",
    title: "Denver Westword's Best new Restaurants in Denver",
  },
  {
    publication: "Thrillist",
    href:
      "https://www.thrillist.com/eat/denver/chefs-pick-denver-s-best-brunch",
    title: "Best Brunch according to Denver Chefs",
  },
  {
    publication: "Thrillist",
    href:
      "https://www.thrillist.com/eat/denver/the-best-restaurants-in-denver-right-now",
    title: "Best Restaurants in Denver",
  },
  {
    publication: "The Westword",
    href:
      "http://www.westword.com/restaurants/100-favorite-dishes-ham-and-egg-congee-at-onefold-6658899",
    title: "100 favorite dishes Denver Westword",
  },
  {
    publication: "Eater",
    href:
      "http://denver.eater.com/maps/best-breakfast-bloody-mary-biscuit-bagels",
    title: "Eater's best breakfast dishes",
  },
  {
    publication: "Eater",
    href: "http://denver.eater.com/maps/best-brunch-denver",
    title: "Eater's best brunch heatmap",
  },
  {
    publication: "Eater",
    href: "http://denver.eater.com/maps/cheap-cocktails-denver",
    title: "Eater's best cheap fast cocktails",
  },
  {
    publication: "303 magazine",
    href: "http://303magazine.com/2015/12/food-news-2/",
    title: "303 magazine's new lunch menus",
  },
  {
    publication: "The Westword",
    href:
      "http://www.westword.com/restaurants/three-signs-that-filipino-cuisine-will-become-mainstream-in-2016-7525099",
    title: "Westword write up on Filipino food",
  },
  {
    publication: "Zagat",
    href: "https://www.zagat.com/l/denver/the-10-best-brunches-in-denver",
    title: "Zagat 10 best brunches in Denver",
  },
  {
    publication: "Zagat",
    href:
      "https://www.zagat.com/b/denver/10-best-brunch-dishes-and-drinks-in-denver#9",
    title: "Best brunch tacos in Denver",
  },
  {
    publication: "Zagat",
    href:
      "https://www.zagat.com/b/denver/the-10-most-important-denver-restaurant-openings-of-2015#9",
    title: "Most important openings of 2015",
  },
  {
    publication: "News.Com.Au",
    href:
      "https://www.news.com.au/lifestyle/food/restaurants-bars/angry-chefs-perfect-response-to-nasty-restaurant-review/news-story/e6ab9b9bb42aaa98ce3deb8a4ee868ec",
    title: "Angry chef’s perfect response to nasty restaurant review",
  },
  {
    publication: "kdvr.com",
    href:
      "https://kdvr.com/2016/03/09/tough-to-swallow-restaurant-owners-fight-back-against-negative-yelp-reviews/",
    title:
      "Tough to swallow: Restaurant owners fight back against negative Yelp reviews",
  },
  {
    publication: "Rooster Magazine",
    href:
      "https://therooster.com/blog/denver-restaurant-owner-fires-back-peoples-shitty-yelp-reviews",
    title: "Denver restaurant owner fires back at people's shitty Yelp reviews",
  },
  {
    publication: "GroundFloor Media",
    href:
      "http://groundfloormedia.com/blog/2017/06/popular-denver-restaurant-regularly-puts-online-reviews-blast/",
    title:
      "OWNER OF POPULAR DENVER RESTAURANT REGULARLY PUTS ONLINE CRITICS ON BLAST",
  },
];
