import React from "react";
import { press } from "../data";
import { PressDescription } from "./PressDescription";

export const About = () => {
  return (
    <React.Fragment>
      <span className="text-center">
        <h1>ABOUT</h1>
      </span>
      <dl className="row">
        <dd className="col-sm-12 mb-4">
          We are a breakfast and lunch restaurant that serves family inspired
          recipes made from scratch.
        </dd>
        <dt className="col-sm-2">Hours:</dt>
        <dd className="col-sm-10">
          <p>Monday - Friday 7:00am - 3:00pm</p>
          <p>Saturday & Sunday 8:00am - 3:00pm</p>
        </dd>
      </dl>

      <span className="text-center">
        <h1>PRESS</h1>
      </span>
      {press.map((link, idx) => (
        <PressDescription key={idx} data={link} />
      ))}
    </React.Fragment>
  );
};
