import React from "react";
import { Button, ButtonGroup, Col, Row, Image } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaCreditCard,
  FaUber,
} from "react-icons/fa";
import { BiFoodMenu } from "react-icons/bi";
import ToggleLocation from "./ToggleLocation";

export const Home = (props) => {
  const { telephone, address, geo, square, delivery, giftcard, name,catering} = props.locData;

  var bgColors = { "button": "#81b29a",
                    "font": "#f4f1de",
		 };
  return (
    <Row>
      <Col sm={12}>
        <div className="d-flex justify-content-center">
          <Image
            src="/images/logo.png"
            alt="onefold - home"
            className="d-inline-block align-top"
            id="onefold"
          />
        </div>
        <div className="d-flex justify-content-center mt-4 text-uppercase">
          <ButtonGroup vertical style={{ width: "400px", color: "#81b29a"}}>
            <Button  href={square} className="mb-2 btn-secondary">
              <span>Locations</span>
            </Button>
	    
            <Button  href={square} className="mb-2 btn-secondary">
              <span>Order Online</span>
            </Button>
	    
            <Button href={delivery} className="mb-2 btn-secondary">
              &nbsp;&nbsp;
              <span>Delivery</span>
            </Button>
            <Button href={catering} className="mb-2 btn-secondary">
              <span>Catering</span>
            </Button>
            <Button
              href="https://www.instagram.com/onefold_denver/?hl=en"
              className="mb-2 btn-secondary"
            >
              <span>Instagram</span>
            </Button>
            <Button
              href="https://www.toasttab.com/onefold-union-station/giftcards"
              className="mb-2 btn-secondary"
            >
              <span>Buy Giftcard</span>
            </Button>
          </ButtonGroup>
        </div>
      </Col>
    </Row>
  );
};
