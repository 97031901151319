import React from "react";
import { Navbar } from "react-bootstrap";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Navbar className="justify-content-center footer">
      <p className="text-secondary mt-3">© Onefold {year}</p>
    </Navbar>
  );
};
