import React from "react";
import { Card } from "react-bootstrap";

export const FoodCard = (props) => {
  return (
    <Card style={{ minHeight: "140px" }}>
      <Card.Body>
        <Card.Title>
          <Card.Subtitle>{props.data.name}</Card.Subtitle>
        </Card.Title>
        <Card.Text>{props.data.desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};
