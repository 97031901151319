import React from "react";
import { Col, Row } from "react-bootstrap";
import { FoodCard } from "./FoodCard";
import { cateringBreakfast, cateringLunch } from "../data";

export const Catering = () => {
  return (
    <div>
      <span className="text-center">
        <h1>CATERING</h1>
      </span>
      <p className="text-justify">
        Catering is only Monday - Friday, We will deliver within a two mile
        radius ($8 surcharge) free deliveries on orders over $100. We may be
        able to prepare your order with no notice however for guaranteed items
        please give 24 hour notice. Please email orders to&nbsp;
        <a
          href="mailto:orders@onefolddenver.com?Subject=Catering%20Order"
          target="_top"
        >
          orders@onefolddenver.com
        </a>
      </p>
      <h3>Breakfast</h3>
      <Row>
        {cateringBreakfast.map((item, idx) => (
          <Col key={idx} md="6" className="p-1">
            <FoodCard data={item} />
          </Col>
        ))}
      </Row>
      <h3>Lunch</h3>
      <Row>
        {cateringLunch.map((item, idx) => (
          <Col key={idx} md="6" className="p-1">
            <FoodCard data={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
