import React, { useState } from "react";

import { Container, Nav, Navbar } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaYelp, FaInstagram, FaTwitter, FaEnvelope } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const NavBar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleToggle = (e) => {
    setShow(true);
  };

  return (
    <Navbar expand="lg" onToggle={handleToggle}>
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img
            src="/images/logo_small.png"
            alt="onefold - home"
            className="d-inline-block align-top"
            id="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar
          className="d-none d-lg-flex"
          id="basic-navbar-nav"
          style={{ width: "100%" }}
        >
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={NavLink} to="/ingredients">
              Ingredients
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="https://www.yelp.com/biz/onefold-denver?sort_by=rating_desc">
              <FaYelp />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/onefold_denver/">
              <FaInstagram />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/OneFoldDenver">
              <FaTwitter />
            </Nav.Link>
            <Nav.Link href="mailto:info@onefolddenver.com">
              <FaEnvelope />
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
      <Offcanvas show={show} onHide={handleClose} style={{ width: "auto" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Navbar>
            <Nav className="flex-column">
              <Nav.Link as={NavLink} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} to="/catering">
                Catering
              </Nav.Link>
              <Nav.Link as={NavLink} to="/ingredients">
                Ingredients
              </Nav.Link>
              <Nav.Link href="https://www.yelp.com/biz/onefold-denver?sort_by=rating_desc">
                <FaYelp /> Yelp
              </Nav.Link>
              <Nav.Link href="https://www.instagram.com/onefold_denver/">
                <FaInstagram /> Instagram
              </Nav.Link>
              <Nav.Link href="https://twitter.com/OneFoldDenver">
                <FaTwitter /> Twitter
              </Nav.Link>
              <Nav.Link href="mailto:info@onefolddenver.com">
                <FaEnvelope /> Email Us
              </Nav.Link>
            </Nav>
          </Navbar>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
};
