import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { About } from "./About";
import { Catering } from "./Catering";
import { Ingredients } from "./Ingredients";
import { Home } from "./Home";

const uptown = {
    name: "Uptown",
    telephone: "303-954-0877",
    address: "1420 E 18th Ave. Denver, CO",
    geo: "1420+18th+Ave,+Denver,+CO+80218",
  square: "https://order.eatonefold.com",
    delivery: "https://www.ubereats.com/store/onefold/Lu58a4mkRPKvjyA6L2NRLQ",
  giftcard: "https://squareup.com/gift/CV7NC4C5A2CY2/order",
  catering: "https://www.ezcater.com/brand/pvt/onefold-1"
},
  unionStation = {
    name: "Union Station",
    telephone: "720-788-7550",
    address: "1919 19th St. Denver, CO",
    geo: "1919+19th+St,+Denver,+CO+80202",
    square: "https://order.eatonefold.com",
    delivery: "https://www.ubereats.com/store/onefold-19th-st/lxn1ZCC1TISYcTIj2Ek8mA",
    giftcard: "https://www.toasttab.com/onefold-union-station/giftcards",
    catering: "https://www.ezcater.com/brand/pvt/onefold-1"
  };

function App() {
  // const history = useHistory();
  // const pathname = history.location.pathname;

  const platform = navigator.platform;

  function openMap(loc) {
    if (platform === "iPhone" || platform === "iPad") {
      window.open(`https://maps.apple.com/?q=${loc}`);
    } else {
      window.open(`https://maps.google.com/?q=${loc}`);
    }
  }

  return (
    <Router>
      <NavBar openMap={openMap} />
      <Container className="main-container">
        <Switch>
          <Route exact path="/">
            <Home openMap={openMap} locData={uptown} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/catering">
            <Catering />
          </Route>
          <Route path="/ingredients">
            <Ingredients />
          </Route>
          <Route path="/location/uptown">
            <Home openMap={openMap} locData={uptown} />
          </Route>
          <Route path="/location/union-station">
            <Home openMap={openMap} locData={unionStation} />
          </Route>
        </Switch>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
